import PressService from '@/services/PressService'

const MUTATION_TYPES = {
    SET_TOTAL: 'SET_TOTAL',
    SET_PRESS_RELEASES: 'SET_PRESS_RELEASES'
}

export default ({
    namespaced: true,
    state: () => ({
        total: 0
    }),
    mutations: {
        [MUTATION_TYPES.SET_TOTAL]: (state, total) => {
            state.total = total
        }
    },
    actions: {
        async getPressReleases ({ commit }, { limit, search }) {
            let result = await PressService.getPressReleases(limit, search)
            commit(MUTATION_TYPES.SET_TOTAL, result.meta.pagination.total)
            return result.data
        },
        async getPressRelease ({ commit }, { id }) {
            let result = await PressService.getPressRelease(id)
            return result.data[0]
        }
    },
    modules: {
    }
})
