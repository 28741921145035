import Vue from 'vue'
import Vuex from 'vuex'
import pressRelease from '@/store/pressRelease'
import news from '@/store/news'
import mediaLibrary from '@/store/mediaLibrary'
import mediaResource from '@/store/mediaResource'
import partners from '@/store/partners'
import PressService from '@/services/PressService'

Vue.use(Vuex)

const MUTATION_TYPES = {
    SET_SEARCH: 'SET_SEARCH'
}

export default new Vuex.Store({
    namespaced: true,
    state: {
        search: ''
    },
    mutations: {
        [MUTATION_TYPES.SET_SEARCH]: (state, search) => {
            state.search = search
        }
    },
    actions: {
        async getQuotes ({ commit }, { limit }) {
            let result = await PressService.getQuotes(limit)
            return result.data
        },
        async sendContact ({ commit }, { email, content, object, name, type }) {
            let result = await PressService.sendContact(email, content, object, name, type)
            return result
        },
        async setSearch ({ commit }, { search }) {
            commit(MUTATION_TYPES.SET_SEARCH, search)
        }
    },
    modules: {
        pressRelease,
        mediaLibrary,
        mediaResource,
        partners,
        news
    }
})
