<template>
    <custom-section
        v-if="quotes.length"
        class="quotes-section">
        <Carousel
            :autoplay="true"
            :loop="true"
            :autoplay-timeout="5000"
            :autoplay-hover-pause="true"
            :per-page="1"
            :touch-drag="true"
            pagination-active-color="#0084ff"
            pagination-color="#555d62">
            <Slide
                v-for="(quote, index) in quotes"
                :key="index"
                class="quote">
                <a
                    class="logo"
                    :href="quote.attributes.url"
                    target="_blank">
                    <img :src="getMedia(quote.attributes.logo)" />
                </a>

                <div class="text">
                    {{ quote.attributes.text }}
                </div>
            </Slide>
        </Carousel>
    </custom-section>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'
import CustomSection from '@/components/common/custom-section'
import { mapActions } from 'vuex'
import { getMedia } from '@/utils/common'

export default {
    name: 'Quotes',
    components: { CustomSection, Carousel, Slide },
    data () {
        return {
            quotes: [],
            limit: 5
        }
    },
    computed: {

    },
    async mounted () {
        this.quotes = await this.getQuotes({ category: 'news', limit: this.limit })
    },
    methods: {
        ...mapActions({
            getQuotes: 'getQuotes'
        }),
        getMedia (logo) {
            return getMedia(logo, 'original')
        }
    }
}
</script>

<style lang="scss" scoped>
.quotes-section {
    width: 100%;
    margin-top: 40px;

    .VueCarousel{
        width: 100%;
    }
    .quote {
        @include flex(row,center, space-between);
        .logo {
            width: 100px;
            margin-right: 60px;
        }

        .text {
            padding: 20px;
        }

        @include breakpoint($max: tablet) {
            @include flex(column,center, center);
        }

    }
}
</style>
