<template>
    <div id="app">
        <router-view :key="$route.fullPath" />
    </div>
</template>

<script>

export default {
    watch: {

    },
    metaInfo: function () {
        return {
            title: this.$t('meta.' + this.$route.name + '.title'),
            meta: [
                { name: 'description', content: this.$t('meta.' + this.$route.name + '.description') }
            ]
        }
    }
}
</script>

<style lang="scss">
body{
    overflow-x: hidden;
    scroll-behavior: smooth;
}

#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: white;
    font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
    font-size: 15px;
    overflow-x: hidden;
    overflow-y: hidden;
    margin-top: 4em;
}
a, p, h1, h2, h3, button {
    font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
}

a {
    color: white !important;
    text-decoration: none !important;
}
a:link, a:visited {
    color: white;
}
a:hover {
    color: white;
}

/* width */
::-webkit-scrollbar {
width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
background: $grey-1;
}

/* Handle */
::-webkit-scrollbar-thumb {
background: $grey-5;
}
* {
    box-sizing: border-box;
}

</style>
