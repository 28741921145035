<template>
    <div class="main-container">
        <Header />
        <div class="content-container">
            <slot />
        </div>
        <Footer />
    </div>
</template>

<script>
import Footer from '@/components/Footer'
import Header from '@/components/Header'
export default {
    name: 'DefaultLayout',
    components: { Header, Footer }
}
</script>

<style  lang="scss" scoped>
.content-container {
    min-height: 80vh;
}
</style>
