<template>
    <div
        v-if="article.attributes"
        class="article">
        <div class="top">
            <div
                class="back"
                @click="goBack">
                <img src="../../static/icons/back.png" />
                Back
            </div>
            <Title
                :title="sectionTitle" />
            <div class="social">
                <a
                    target="_blank"
                    :href="shareLink('https://www.facebook.com/sharer/sharer.php?title=' + article.attributes.title + '&u=')">
                    <img
                        class="icon"
                        src="../../static/icons/facebook.svg" /></a>
                <a
                    target="_blank"
                    :href="shareLink('https://twitter.com/intent/tweet?url=' + url + '+')"><img
                        class="icon"
                        src="../../static/icons/twitter.svg" /></a>
            </div>
        </div>
        <div class="content">
            <div
                class="media">
                <img :src="getMedia(article.attributes.image)" />
            </div>
            <div class="date-time">
                {{ article.attributes.publishedAt | moment("LL") }} {{ readingTime }}
            </div>
            <div class="title">
                <h3>{{ article.attributes.title }}</h3>
            </div>
            <div
                v-if="tags"
                class="tags">
                <div
                    v-for="(tag, index) in tags"
                    :key="index"
                    class="tag">
                    {{ tag.trim() }}
                </div>
            </div>
            <div
                class="description"
                v-html="description" />
        </div>
        <div
            v-if="article.attributes.video && article.attributes.video.data"
            class="media">
            <video
                width="400"
                controls>
                <source
                    :src="getMedia(article.attributes.video)"
                    type="video/mp4" />
            </video>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import Title from '@/components/common/title'
import { getMedia } from '@/utils/common'

export default {
    name: 'Article',
    components: { Title },
    metaInfo: function () {
        return {
            title: this.article.attributes ? this.article.attributes.title : 'Press article',
            meta: [
                { name: 'description', content: '' }
            ]
        }
    },
    props: {
    },
    data () {
        return {
            article: {}
        }
    },
    computed: {
        sectionTitle () {
            let title
            switch (this.$router.currentRoute.name) {
            case 'MediaResource':
                title = this.$t('titles.media_resources')
                break
            case 'OneNews':
                title = this.$t('titles.news')
                break
            case 'MediaLibrary':
                title = this.$t('titles.media_libraries')
                break
            case 'PressRelease':
                title = this.$t('titles.press_release')
                break
            }
            return title
        },
        readingTime () {
            return this.article.attributes.reading_time ? ` - ${this.article.attributes.reading_time} ${this.$t('common.min_read')}` : ''
        },
        description () {
            let showdown = require('showdown')
            let converter = new showdown.Converter()
            return converter.makeHtml(this.article.attributes.description)
        },
        url () {
            return window.location.href
        },
        tags () {
            return this.article.attributes.tags ? this.article.attributes.tags.split(',') : null
        }
    },
    async mounted () {
        await this.getArticle()
    },
    methods: {
        ...mapActions({
            getMediaResource: 'mediaResource/getMediaResource',
            getMediaLibrary: 'mediaLibrary/getMediaLibrary',
            getOneNews: 'news/getOneNews',
            getPressRelease: 'pressRelease/getPressRelease'
        }),
        async getArticle () {
            switch (this.$router.currentRoute.name) {
            case 'MediaResource':
                this.article = await this.getMediaResource({ id: this.$route.params.id })
                break
            case 'OneNews':
                this.article = await this.getOneNews({ id: this.$route.params.id })
                break
            case 'MediaLibrary':
                this.article = await this.getMediaLibrary({ id: this.$route.params.id })
                break
            case 'PressRelease':
                this.article = await this.getPressRelease({ id: this.$route.params.id })
                break
            }
        },
        goBack () {
            window.history.length > 2 ? window.history.back() : this.$router.push({ name: 'Home' })
        },
        getMedia (image) {
            return getMedia(image, 'image', 'medium')
        },

        shareLink (fragment) {
            return fragment + window.location.origin + window.location.pathname
        }
    }
}
</script>

<style lang="scss" scoped>
.article {
    @include flex(column, center, center);
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
    padding: 40px ;

    .top {
        width: 100%;
        @include flex(row, center, space-between);
        .back {
            cursor: pointer;
            font-style: italic;
            img {
                width: 32px;
            }
        }

        .title {
            padding-top: 0px;
            max-width: 600px;
        }

        .social {
            @include flex(row, center, space-between);
            .icon {
                cursor: pointer;
                background-color: $grey-5;
                border-radius: 50px;
                width: 32px;
                margin-left: 10px;
                padding: 5px;
            }
        }
    }

    .content {
        @include flex(column, flex-start, center);
        width: 100%;
        height: auto;
        margin-bottom: 20px;
        background-color: $gray4;
        border-radius: 20px;
        padding: 40px;

        .date-time {
            font-style: italic;
            margin-bottom: 20px;
        }

        .tags {
            width: 100%;
            margin-bottom: 40px;
            @include flex(row, center, flex-start);
            flex-wrap: wrap;

            .tag {
                margin-right: 8px;
                padding: 6px 8px;
                height: auto;

                background-color: darkgray;
                border: 1px solid darkgray;
                border-radius: 20px;

                text-transform: uppercase;
                font-size: 12px;
                font-weight: bold;

                @include breakpoint($max: mobile) {
                    margin-bottom: 20px;
                }
            }
        }

        h3 {
            font-weight: bold;
            margin-bottom: 20px;
        }

        .media {
            width: 60%;
            margin: 20px;
            margin-left: auto;
            margin-right: auto;
            @include breakpoint($device: mobile) {
                width: 90%;
            }
            img {
                width: 100%;
                border-radius: 20px;
                @include breakpoint($device: mobile) {
                    border-radius: 10px;
                }
            }

        }

        .description {
            text-align: left;
            white-space: pre-line;
            text-decoration: initial;
        }
    }

    video {
        width: 100%;
        max-width: 100%;
        height: auto;
    }

}
</style>
