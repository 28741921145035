// import i18n from '@/i18n'
import PressService from '@/services/PressService'

const MUTATION_TYPES = {
    SET_CATEGORIES: 'SET_CATEGORIES',
    SET_CATEGORY: 'SET_CATEGORY',
    SET_TOTAL: 'SET_TOTAL',
    SET_MEDIA_RESOURCES: 'SET_MEDIA_RESOURCES'
}

export default {
    namespaced: true,
    state: () => ({
        categories: [],
        category: '',
        total: 0
    }),
    mutations: {
        [MUTATION_TYPES.SET_CATEGORIES]: (state, categories) => {
            state.categories = categories
            state.categories.splice(1, 0, { id: 2, attributes: { name: 'news' } })
            if (!state.category) {
                state.category = categories[0]
            }
        },
        [MUTATION_TYPES.SET_CATEGORY]: (state, category) => {
            state.category = category
        },
        [MUTATION_TYPES.SET_TOTAL]: (state, total) => {
            state.total = total
        }
    },
    actions: {
        async getCategories ({ commit }) {
            let result = await PressService.getMediaResourceCategories()
            commit(MUTATION_TYPES.SET_CATEGORIES, result.data)
        },
        async setCategory ({ commit }, { category }) {
            commit(MUTATION_TYPES.SET_CATEGORY, category)
        },
        async getMediaResources ({ commit }, { category, limit, search, data = true }) {
            let result = await PressService.getMediaResources(category, limit, search)
            if (data) commit(MUTATION_TYPES.SET_TOTAL, result.meta.pagination.total)
            return data ? result.data : result.meta.pagination.total
        },
        async getMediaResource ({ commit }, { id }) {
            let result = await PressService.getMediaResource(id)
            return result.data[0]
        }
    },
    modules: {
    }
}
