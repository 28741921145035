import PressService from '@/services/PressService'

const MUTATION_TYPES = {
    SET_TOTAL: 'SET_TOTAL',
    SET_NEWS: 'SET_NEWS'
}

export default ({
    namespaced: true,
    state: () => ({
        total: 0
    }),
    mutations: {
        [MUTATION_TYPES.SET_TOTAL]: (state, total) => {
            state.total = total
        }
    },
    actions: {
        async getNews ({ commit }, { limit, search, resource, data = true }) {
            let result = await PressService.getNews(limit, search, resource)
            if (data) commit(MUTATION_TYPES.SET_TOTAL, result.meta.pagination.total)
            return data ? result.data : result.meta.pagination.total
        },
        async getOneNews ({ commit }, { id }) {
            let result = await PressService.getOneNews(id)
            return result.data[0]
        }
    },
    modules: {
    }
})
