import { strapiBase } from '@/utils/environment'

export const getMedia = (media, type = 'image', format = 'small') => {
    if (type === 'image') {
        return media.data ? `${strapiBase}${media.data.attributes.formats[format].url}` : ''
    } else {
        return media.data ? `${strapiBase}${media.data.attributes.url}` : ''
    }
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}
