var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"custom-textarea"},[_c('textarea',{class:{
            foredit: _vm.foredit,
            block: _vm.block,
            error: _vm.error,
            textAlignLeft: _vm.textAlignLeft,
            textAlignRight: _vm.textAlignRight,
            textAlignCenter: _vm.textAlignCenter,
            dark: _vm.dark
        },attrs:{"placeholder":_vm.placeholder,"cols":_vm.cols,"rows":_vm.rows,"maxlength":_vm.maxlength,"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)}}}),_vm._v(" "),(_vm.maxlength !== undefined)?_c('p',{class:{
            max: _vm.maxlength !== undefined && _vm.value && _vm.value.length == _vm.maxlength
        }},[_vm._v(" "+_vm._s(_vm.value ? _vm.value.length : 0)+" / "+_vm._s(_vm.maxlength)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }