<template>
    <custom-section
        id="anchor-press-release">
        <router-link :to="{ name: 'PressReleases'}">
            <div class="title">
                <h1>{{ $t('titles.press_release') }}</h1>
            </div>
        </router-link>

        <div v-if="articles.length">
            <div class="articles-container">
                <div
                    v-for="(elem, i) in articles"
                    :key="i"
                    class="article is-cursor">
                    <router-link :to="{ name: 'PressRelease', params: {id: elem.id}}">
                        <content-horizontal
                            class="horizontal"
                            :image="elem.attributes.image"
                            :title="elem.attributes.title"
                            :description="elem.attributes.description"
                            :date="elem.attributes.publishedAt" />
                        <content-vertical
                            class="vertical"
                            :image="elem.attributes.image"
                            :title="elem.attributes.title"
                            :description="elem.attributes.description"
                            :date="elem.attributes.publishedAt" />
                    </router-link>
                </div>
            </div>
            <div
                v-if="limit < total"
                class="load-more"
                @click="loadMore">
                {{ $t('common.load_more') }}
            </div>
        </div>
        <div v-else>
            {{ $t('common.no_content') }}
        </div>
    </custom-section>
</template>

<script>
import CustomSection from '@/components/common/custom-section'
import { mapActions } from 'vuex'
import ContentHorizontal from '@/components/common/content-horizontal'
import ContentVertical from '@/components/common/content-vertical'

export default {
    name: 'PressRelease',
    components: { ContentVertical, ContentHorizontal, CustomSection },
    data () {
        return {
            articles: [],
            limit: 3,
            step: 3
        }
    },
    computed: {
        total () {
            return this.$store.state.pressRelease.total
        },
        search () {
            return this.$store.state.search
        }
    },
    watch: {
        async search () {
            await this.getArticles()
        }
    },
    async mounted () {
        if (this.$router.currentRoute.name === 'PressReleases') {
            this.limit = 9
            this.step = 9
        }
        await this.getArticles()
    },
    methods: {
        ...mapActions({
            getPressReleases: 'pressRelease/getPressReleases'
        }),
        async getArticles () {
            this.articles = await this.getPressReleases({ limit: this.limit, search: this.search })
        },
        async loadMore () {
            this.limit += this.step
            await this.getArticles()
        }
    }
}
</script>

<style lang="scss" scoped>
.load-more {
    align-self: center;
    font-style: italic;
    font-size: 18px;
    transition: 200ms;

    &:hover {
        text-decoration: none;
        cursor: pointer;
    }
}

.title {
    margin-bottom: 30px;
    padding-top: 100px;
    @include breakpoint($device: mobile) {
        padding-top: 80px;
    }

    h1 {
        margin: 0;
        font-size: 50px;
        font-weight: Bold;
        text-transform: uppercase;
        @include breakpoint($device: touch) {
            font-size: 25px;
        }
    }
}

.articles-container {
    @include flex(column, flex-start, space-around);
    flex-wrap: wrap;
    width: 100%;
    .vertical {
        display: none;
    }
    .horizontal {
        display:  flex;
    }
    @include breakpoint($device: touch) {
        @include flex(row, center, space-around);
        .vertical {
            display: inline-block;
        }
        .horizontal {
            display: none;
        }
    }
}
</style>
