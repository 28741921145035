<template>
    <default-layout>
        <search-input v-if="search" />
        <PressRelease />
    </default-layout>
</template>

<script>

import PressRelease from '@/components/PressReleaseSection'
import { capitalizeFirstLetter } from '@/utils/common'
import DefaultLayout from '@/layout/default'
import SearchInput from '@/components/SearchInput'

export default {
    name: 'PressReleases',
    components: {
        SearchInput,
        DefaultLayout,
        PressRelease

    },
    metaInfo: function () {
        return {
            title: capitalizeFirstLetter(this.$t('titles.press_release')),
            meta: [
                { name: 'description', content: '' }
            ]
        }
    },
    computed: {
        search () {
            return this.$store.state.search
        }
    }
}
</script>
