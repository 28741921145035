<template>
    <div class="title">
        <h1>{{ title }}</h1>
    </div>
</template>

<script>

export default {
    props: {
        title: { type: String, default: '' }
    },
    computed: {

    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
.title {
    margin-bottom: 30px;
    padding-top: 60px;
    @include breakpoint($device: mobile) {
        padding-top: 80px;
    }

    h1 {
        margin: 0;
        font-size: 50px;
        font-weight: bold;
        text-transform: uppercase;
        @include breakpoint($device: touch) {
            font-size: 25px;
        }
    }
}
</style>
