import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import vClickOutside from 'v-click-outside'
import VueCarousel from 'vue-carousel'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueTilt from 'vue-tilt.js'
import AOS from 'aos'
import VueMeta from 'vue-meta'
import 'aos/dist/aos.css'

// import { library } from '@fortawesome/fontawesome-svg-core'
// import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap-vue/dist/bootstrap-vue.js'
import 'font-awesome/css/font-awesome.min.css'

// library.add(faTimes)

Vue.use(VueMeta)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(vClickOutside)
Vue.use(VueTilt)
Vue.use(VueCarousel)
Vue.use(VueAxios, axios)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(require('vue-moment'))

Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    created () {
        AOS.init()
    },
    render: function (h) { return h(App) }
}).$mount('#app')
