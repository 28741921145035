<template>
    <div class="custom-textarea">
        <textarea
            :value="value"
            :placeholder="placeholder"
            :cols="cols"
            :rows="rows"
            :maxlength="maxlength"
            :disabled="disabled"
            :class="{
                foredit,
                block,
                error,
                textAlignLeft,
                textAlignRight,
                textAlignCenter,
                dark
            }"
            @input="$emit('input', $event.target.value)" />
        <p
            v-if="maxlength !== undefined"
            :class="{
                max: maxlength !== undefined && value && value.length == maxlength
            }">
            {{ value ? value.length : 0 }} / {{ maxlength }}
        </p>
    </div>
</template>

<script>
export default {
    props: {
        value: { type: String, default: '' },
        placeholder: { type: String, default: '' },
        cols: { type: String, default: '' },
        rows: { type: String, default: '' },
        maxlength: { type: [Number, String], default: 250 },
        disabled: { type: Boolean },
        error: { type: Boolean },
        foredit: { type: Boolean, default: false },
        block: { type: Boolean, default: false },
        textAlignLeft: { type: Boolean, default: false },
        textAlignRight: { type: Boolean, default: false },
        textAlignCenter: { type: Boolean, default: false },
        dark: { type: Boolean, default: false }
    }
}
</script>

<style lang="scss" scoped>
.custom-textarea {
    width: 100%;
    position: relative;
    margin-bottom: 6px;
    padding-bottom: 30px;
    p {
        position: absolute;
        bottom: 12px;
        right: 17px;
        margin: 0;

        color: $gray7;
        font-size: 14px;
        line-height: 18px;
        &.max {
            color: white;
            font-weight: 600;
        }
    }

    &.correct {
        textarea {
            border-color: green;
        }
    }
    &.error {
        textarea {
            border-color: red;
        }
    }
    textarea {
        width: 100%;
        color: white;
        line-height: 20pt;
        text-align: left;
        padding: 6px 18px;
        background-color: $gray4;
        border: solid;
        border-color: $gray7;
        border-width: 2px;
        border-radius: 20px;
        outline: none !important;

        resize: none;
        &.foredit {
            max-width: 580px;
            height: 100px;
        }
        &.block {
            max-width: 100%;
        }
        &::placeholder {
            color: $gray7;
            font-weight: 600;
            text-align: left;
        }
        &:hover {
            &:not([disabled]) {
                border-color: $gray8;
            }
        }
        &:focus {
            border-color: $gray8 !important;
        }
        &.dark {
            background-color: $gray2;
        }
    }
}
</style>
