var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:{
        disabled: _vm.disabled,
        alternative: _vm.alternative,
        outlined: _vm.outlined,
        big: _vm.big,
        auto: _vm.auto,
        block: _vm.block,
        small: _vm.small,
        xsmall: _vm.xsmall,
        tabLike: _vm.tabLike,
        login: _vm.login,
        'alternative-hover': _vm.alternativeHover,
        'has-border': _vm.hasBorder
    },style:(_vm.style),attrs:{"color":_vm.color || 'primary',"type":"button","disabled":_vm.disabled},on:{"click":function($event){_vm.disabled && !_vm.canClick ? {} : _vm.$emit('click')}}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }