<template>
    <custom-section
        id="anchor-partners"
        class="partners-section">
        <Title :title="$t('titles.partners')" />

        <ul class="partners-logo">
            <li
                v-for="(item, i) in partners"
                :key="i"
                class="partner-logo">
                <a
                    :href="item.attributes.path"
                    target="_blank">
                    <img
                        :src="getMedia(item.attributes.image)"
                        class="logo" />
                </a>
            </li>
        </ul>
    </custom-section>
</template>

<script>
import CustomSection from '@/components/common/custom-section'
import { mapActions } from 'vuex'
import { strapiBase } from '@/utils/environment'
import Title from '@/components/common/title'

export default {
    name: 'Partners',
    components: { Title, CustomSection },
    computed: {
        partners () {
            return this.$store.state.partners.partners
        }
    },
    mounted () {
        this.getPartners()
    },
    methods: {
        ...mapActions({
            getPartners: 'partners/getPartners'
        }),
        getMedia (image) {
            return image.data ? `${strapiBase}${image.data.attributes.url}` : ''
        }
    }
}
</script>

<style lang="scss" scoped>
ul {
    list-style-type: none;
}

.partners-section {
    background-color: $gray2;
    .partners-logo {
        @include flex(row, center, center);
        flex-wrap: wrap;
        padding-top: 40px;
        padding-bottom: 20px;
        @include breakpoint($max: mobile) {
            padding: 0px;
        }

        .partner-logo {
            @include flex(column, center, center);
            cursor: pointer;
            width: 120px;
            height: 80px;
            margin: 20px;
            @include breakpoint($max: mobile) {
                width: 100px;
            }
            a {
                width: 100%;
                height: 100%;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

        }
    }
}
</style>
