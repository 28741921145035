<template>
    <button
        :class="{
            disabled,
            alternative,
            outlined,
            big,
            auto,
            block,
            small,
            xsmall,
            tabLike,
            login,
            'alternative-hover': alternativeHover,
            'has-border': hasBorder
        }"
        :style="style"
        :color="color || 'primary'"
        type="button"
        :disabled="disabled"
        @click="disabled && !canClick ? {} : $emit('click')">
        <slot />
    </button>
</template>

<script>
export default {
    props: {
        color: { type: String, default: '' },
        disabled: { type: Boolean },
        canClick: { type: Boolean },
        alternative: { type: Boolean },
        alternativeHover: { type: Boolean },
        hasBorder: { type: Boolean },
        outlined: { type: Boolean },
        outlinedColor: { type: String, default: '' },
        auto: { type: Boolean },
        block: { type: Boolean },
        small: { type: Boolean },
        xsmall: { type: Boolean },
        login: { type: Boolean },
        big: { type: Boolean },
        tabLike: { type: Boolean },
        maxWidth: { type: Number, default: undefined },
        minWidth: { type: Number, default: undefined }
    },
    computed: {
        style () {
            const style = {}
            if (this.outlinedColor && this.outlined) {
                style.borderColor = this.outlinedColor
                style.color = this.outlinedColor
            }
            if (this.maxWidth) style.maxWidth = `${this.maxWidth}px`
            if (this.minWidth) style.maxWidth = `${this.minWidth}px`
            return style
        }
    }
}
</script>

<style lang="scss" scoped>
@mixin smallBtn() {
  font-size: 16px;
  line-height: 20px;
  min-height: 39px;
  max-height: 39px;
  border-radius: 20px;
  padding: 0 24px;
  @include flex(row, center, center);
  min-width: 170px;
  max-width: 195px;
}

button {
  position: relative;
  font-weight: 700;
  color: white;
  text-align: center;
  border: none;
  text-transform: capitalize;
  cursor: pointer;
  font-size: 20px;
  min-width: 250px;
  height: 50px;
  border-radius: 25px;
  padding: 11px 24px;
  line-height: 24px;
  @include breakpoint($device: mobile) {
    @include smallBtn;
  }
  &.tabLike {
    width: 60px;
    min-width: unset;
    height: 30px;
    border-radius: 5px;
    padding: 0;
    font-size: 13px;
    font-family: "Montserrat SemiBold";
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.auto {
    min-width: auto;
  }
  &.small {
    @include smallBtn;
  }
  &.xsmall {
    font-size: 15px;
    line-height: 19px;
    min-height: 30px;
    max-height: 30px;
    border-radius: 20px;
    text-align: center;
    min-width: 130px;
    max-width: 130px;
    color: $gray10 !important;
    padding: unset;
    @include flex(row, center, center);
  }
  &.login {
    height: 29px;
    min-height: unset;
    font-size: 18px;
    min-width: 120px;
    border-radius: 20px;
    padding: 0px 24px;
    margin-left: 10px;
    @media (max-width: 1024px) and (min-width: 480px) {
      height: 40px;
      min-width: 170px;
    }
    @include breakpoint($device: mobile) {
      height: 29px;
      font-size: 13px;
      min-width: 82px;
      margin-left: 0;
      padding: 0 10px;
    }
  }
  &.big {
    font-size: 22px;
    min-height: 60px;
    max-height: 60px;
    border-radius: 30px;
    min-width: 300px;
    padding: 16px 24px;
  }

  &.block {
    min-width: 100% !important;
    width: 100%;
  }

  &.outlined {
    background: rgba(0, 0, 0, 0.2) !important;
    width: 214px;
    //margin-top: 20px;
    min-width: 0;
    border: 2px solid $gray8;
    color: white;
    transition: all 0.25s ease;
    &:hover {
      background: $blue-2 !important;
      border-color: $blue-2;
      color: white !important;
    }
  }
  &.has-border {
    border: 2px solid $gray7;
  }
  svg {
    padding-top: 4px;
  }
  /**/
  &[color="gray"] {
    background-color: $gray5;
    color: $gray10;
    &:hover {
      background-color: $gray3;
    }
  }
  &[color="dark-gray"] {
    background-color: $gray1 !important;
    color: $gray10;
    &:hover {
      background-color: $gray3 !important;
    }
    &.outlined {
      border: 2px solid $gray10;
    }
  }
  /**/

  &[color="primary"] {
    background-color: $blue-1;
    &:hover {
      background-color: $blue-5;
    }
  }

  &[color="secondary"] {
    background-color: $gray9;
    &.alternative {
      background-color: $gray5;
    }
    &:hover {
      background-color: $gray3;
      &.alternative-hover {
        background-color: $gray3;
      }
    }
  }

  &[color="tertiary"] {
    background-color: green;
    &:hover {
      background-color: darkGreen;
    }
  }

  &[color="quaternary"] {
    background-color: $gray6;
    &.alternative {
      background-color: $gray7;
    }
    &:hover {
      background-color: $gray8;
    }
  }

  &[color="light-green"] {
    background-color: lightGreen;
    &:hover {
      background-color: green;
    }
  }

  &[color="error"] {
    background-color: lightRed;
    &:hover {
      background-color: red;
    }
  }

  &[color="transparent"] {
    background-color: transparent !important;
    color: $gray8;
    &:hover {
      color: $gray7;
    }
  }

  &.disabled {
    cursor: initial;
    background-color: $gray5 !important;
    color: $lockedText !important;
    img {
      opacity: 0.3;
    }
  }
}
</style>
