import { strapiApi } from '@/utils/environment'
import i18n from '@/i18n'
import axios from 'axios'

export default {
    async getQuotes (category, limit) {
        let url = `${strapiApi}/quotes?populate=%2A&locale=${i18n.locale}&`
        if (limit) {
            url += `pagination[limit]=${limit}`
        }
        const response = await fetch(url)
        return response.json().then(res => res).catch(() => [])
    },

    async getMediaResourceCategories () {
        const response = await fetch(`${strapiApi}/media-resource-categories`)
        return response.json().then(res => res).catch(() => [])
    },
    async getMediaLibraryCategories () {
        const response = await fetch(`${strapiApi}/media-library-categories`)
        return response.json().then(res => res).catch(() => [])
    },

    async getMediaResources (category, limit, search) {
        let url = `${strapiApi}/media-resources?sort[0]=publishedAt%3Adesc&populate=%2A&locale=${i18n.locale}&`
        if (category) {
            url += `filters[media_resource_category][name][$eq]=${category}&`
        }
        if (search) {
            url += `filters[$or][0][title][$containsi]=${search}&filters[$or][1][tags][$containsi]=${search}&filters[$or][2][description][$containsi]=${search}&`
        }
        if (limit) {
            url += `pagination[limit]=${limit}`
        }
        const response = await fetch(url)
        return response.json().then(res => res).catch(() => [])
    },

    async getMediaResource (id) {
        let url = `${strapiApi}/media-resources?populate=%2A&locale=${i18n.locale}&filters[id][$eq]=${id}`
        const response = await fetch(url)
        return response.json().then(res => res).catch(() => [])
    },

    async getMediaLibraries (category, limit, search) {
        let url = `${strapiApi}/media-libraries?sort[0]=publishedAt%3Adesc&populate=%2A&locale=${i18n.locale}&`
        if (category) {
            url += `filters[media_library_category][name][$eq]=${category}&`
        }
        if (search) {
            url += `filters[$or][0][title][$containsi]=${search}&filters[$or][1][tags][$containsi]=${search}&filters[$or][2][description][$containsi]=${search}&`
        }
        if (limit) {
            url += `pagination[limit]=${limit}`
        }
        const response = await fetch(url)
        return response.json().then(res => res).catch(() => [])
    },

    async getMediaLibrary (id) {
        let url = `${strapiApi}/media-librairies?populate=%2A&locale=${i18n.locale}&filters[id][$eq]=${id}`
        const response = await fetch(url)
        return response.json().then(res => res).catch(() => [])
    },

    async getPressReleases (limit, search) {
        let url = `${strapiApi}/press-releases?sort[0]=publishedAt%3Adesc&populate[image]=%2A&`
        if (search) {
            url += `filters[$or][0][title][$containsi]=${search}&filters[$or][1][tags][$containsi]=${search}&filters[$or][2][description][$containsi]=${search}&`
        }
        if (limit) {
            url += `pagination[limit]=${limit}`
        }

        const response = await fetch(url)
        return response.json().then(res => res).catch(() => [])
    },

    async getNews (limit, search, resource = false) {
        let url = `${strapiApi}/news-plural?sort[0]=publishedAt%3Adesc&populate[image]=%2A&`
        if (search) {
            url += `filters[$or][0][title][$containsi]=${search}&filters[$or][1][tags][$containsi]=${search}&filters[$or][2][description][$containsi]=${search}&`
        }
        if (resource) {
            url += `filters[resource][$eq]=${resource}&`
        }
        if (limit) {
            url += `pagination[limit]=${limit}`
        }

        const response = await fetch(url)
        return response.json().then(res => res).catch(() => [])
    },

    async getPressRelease (id) {
        let url = `${strapiApi}/press-releases?populate=%2A&locale=${i18n.locale}&filters[id][$eq]=${id}`
        const response = await fetch(url)
        return response.json().then(res => res).catch(() => [])
    },

    async getOneNews (id) {
        let url = `${strapiApi}/news-plural?populate=%2A&locale=${i18n.locale}&filters[id][$eq]=${id}`
        const response = await fetch(url)
        return response.json().then(res => res).catch(() => [])
    },

    async getPartners () {
        const response = await fetch(`${strapiApi}/partners?populate=%2A&sort[0]=position%3Aasc`)
        return response.json().then(res => res).catch(() => [])
    },

    async getMedia (id) {
        const response = await fetch(`${strapiApi}/upload/files/${id}`)
        return response.json().then(res => res).catch(() => [])
    },

    async search (search) {
        const response = await fetch(
            `${strapiApi}/media-resources?title_contains=${search}`
        )
        return response.json().then(res => res).catch(() => [])
    },

    async sendContact (email, content, object, name, type) {
        const response = await axios.post(`${strapiApi}/contacts`,
            { data: { email, content, object, name, type } })
        return response
    }
}
