<template>
    <div
        ref="tabs"
        class="tabs no-scrollbar"
        :class="{small: isSmall === true}">
        <div
            v-for="(tab, i) in tabs"
            :key="'tab'+i"
            class="tab"
            :class="{ activated: forcedTabIndex === i+1}"
            @click="OnClick(i+1)">
            {{ tab.label }}
            <div
                v-if="tab.dot"
                class="dot" />
        </div>
    </div>
</template>

<script>

export default {
    model: {
        prop: 'forcedTabIndex',
        event: 'change'
    },
    props: {
        forcedTabIndex: {
            type: Number,
            default: 1
        },
        isSmall: {
            type: Boolean,
            default: false
        },
        tabs: Array
    },
    data: function () {
        return {}
    },
    methods:
        {
            OnClick (i) {
                this.$emit('change', i)
            }
        }
}
</script>

<style lang="scss" scoped>

.tabs {
    width: 100%;
    display: flex;
    flex-wrap: unset;
    //padding-left: 12px;
    //padding-right: 12px;
    padding-bottom: 12px;
    overflow-y: hidden;
}

.tab {
    position: relative;
    padding: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 16px;
    font-weight: 600;
    color: white;
    text-transform: uppercase;
    white-space: nowrap;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    @include breakpoint($device: touch) {
        font-size: 14px;
    }

    .dot {
        height: 8px;
        width: 8px;
        background-color: $blue-1;
        border-radius: 50%;
        position: absolute;
        top: 5px;
        right:0px;
    }
}

.tab.activated {
    border-bottom-color: $blue-1;
}

.small {
    .tab {
        font-weight: 600;
        color: $grey-1;
        padding-bottom: 12px;
        border-bottom: 1px solid darkgray;
    }

    .tab.activated{
        border-bottom: 2px solid black;
    }
}
</style>
