<template>
    <div class="article">
        <div class="media">
            <img :src="getMedia(image)" />
        </div>
        <div class="content">
            <div class="date">
                {{ date | moment("LL") }}
            </div>
            <div class="title">
                {{ title }}
            </div>
            <div
                class="description"
                v-html="descriptionHtml" />
        </div>
    </div>
</template>

<script>
import { getMedia } from '@/utils/common'

export default {
    props: {
        title: { type: String, default: '' },
        image: { type: Object },
        description: { type: String, default: '' },
        date: { type: String, default: '' }
    },
    data () {
        return {
            article: {}
        }
    },
    computed: {
        descriptionHtml () {
            let showdown = require('showdown')
            let converter = new showdown.Converter()
            return converter.makeHtml(this.description)
        }
    },
    methods: {
        getMedia (image) {
            return getMedia(image)
        }
    }
}
</script>

<style lang="scss" scoped>
.article {
    @include flex(row, center, flex-start);
    flex-wrap: nowrap;
    max-width: $max-width;
    padding: 0px 20px ;
    @include breakpoint($max: mobile) {
        padding: 0px;
    }

    @include breakpoint($max: tablet) {
        flex-wrap: wrap;
    }

    .content {
        @include flex(column, flex-start, center);
        height: auto;
        max-width: 760px;
        margin-bottom: 20px;
        padding: 20px;
        @include breakpoint($max: tablet) {
            max-width: $max-width;
            padding: 0px;
            padding-top: 10px;
        }

        .date {
            font-style: italic;
        }

        .title {
            font-weight: bolder;
            font-size: 20px;
            margin-bottom: 20px;
        }

        .description {
            text-align: left;
            white-space: pre-line;
            text-decoration: initial;
        }
    }

    .media {
        max-width: 500px;
        img {
            border-radius: 10px;
            width: 100%;
        }
    }
}
</style>
