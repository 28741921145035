<template>
    <div class="article">
        <div class="image">
            <img :src="getMedia(image)" />
        </div>
        <div class="content">
            <div class="press-title">
                {{ title }}
            </div>
            <div class="description">
                {{
                    description.length > 300
                        ? description.substring(0, 300) + '...'
                        : description
                }}
            </div>
            <div class="date">
                {{ date | moment("LL") }}
            </div>
        </div>
    </div>
</template>

<script>
import { getMedia } from '@/utils/common'

export default {
    props: {
        title: { type: String, default: '' },
        image: { type: Object },
        description: { type: String, default: '' },
        date: { type: String, default: '' }
    },
    computed: {

    },
    methods: {
        getMedia (image) {
            return getMedia(image)
        }
    }
}
</script>

<style lang="scss" scoped>
.article {
    @include flex(row, flex-start, flex-start);
    width: 100%;
    height: 200px;
    margin-bottom: 20px;
    background-color: $gray4;
    border-radius: 0px 20px 20px 0px;
    .image {
        height: 100%;
        img {
            background-size: cover;
            border-radius: 20px 0px 0px 20px;
            height: 100%;
        }
    }
    .content {
        @include flex(column, flex-start, space-between);
        height: 100%;
        padding: 10px 20px;
        .description {
            text-align: left;
        }
        .date {
            align-self: flex-end;
            font-style: italic;
            margin-bottom: 20px
        }
        .press-title {
            padding-top: 20px;
            font-weight: bolder;
            font-size: 20px;
        }
    }
    transition: all 0.25s ease;
    cursor: pointer;
    &:hover {
        transform: scale(1.05);
    }
}
</style>
