<template>
    <custom-section
        id="anchor-media-resources"
        class="resources-section">
        <router-link :to="{ name: 'MediaResources'}">
            <Title :title=" $t('titles.media_resources') " />
        </router-link>
        <tabs
            v-model="currentTab"
            :tabs="tabs" />

        <div class="resources-content">
            <div
                v-if="currentTab === 1">
                <ArticlesList
                    :tab="true"
                    :summary="true"
                    :resource="true"
                    category="about" />
            </div>
            <div
                v-if="currentTab === 2">
                <ArticlesList
                    :tab="true"
                    :resource="true"
                    category="news" />
            </div>
            <div
                v-if="currentTab === 3">
                <ArticlesList
                    :tab="true"
                    :resource="true"
                    category="stats" />
            </div>
            <div
                v-if="currentTab === 4">
                <ArticlesList
                    :tab="true"
                    :resource="true"
                    category="events" />
            </div>
            <div
                v-if="currentTab === 5">
                <ArticlesList
                    :tab="true"
                    :resource="true"
                    category="executive bios" />
            </div>
            <div
                v-if="currentTab === 6">
                <ArticlesList
                    :tab="true"
                    :resource="true"
                    :article="false"
                    :image="true"
                    category="media kit" />
            </div>
        </div>
    </custom-section>
</template>

<script>
import CustomSection from '@/components/common/custom-section'
import Tabs from '@/components/common/custom-tabs'
import { mapActions } from 'vuex'
import ArticlesList from '@/components/articles/ArticlesList'
import Title from '@/components/common/title'

export default {
    name: 'MediaResources',
    components: { Title, ArticlesList, Tabs, CustomSection },
    data () {
        return {
            currentTab: 1,
            tabs: []
        }
    },
    computed: {
        tabsStrapi () {
            return this.$store.state.mediaResource.categories
        },
        search () {
            return this.$store.state.search
        },
        category () {
            return this.$store.state.mediaResource.category
        }
    },
    watch: {
        currentTab () {
            this.setCategory({ category: this.tabsStrapi[this.currentTab - 1] })
        },
        search: async function () {
            await this.manageTabs()
        },
        tabsStrapi: async function () {
            await this.manageTabs()
        }
    },
    mounted () {
        this.getCategories()
        if (this.category) {
            this.currentTab = this.tabsStrapi.findIndex(tab => tab.id === this.category.id) + 1
        }
    },
    methods: {
        ...mapActions({
            getCategories: 'mediaResource/getCategories',
            setCategory: 'mediaResource/setCategory',
            getMediaResources: 'mediaResource/getMediaResources',
            getNews: 'news/getNews'
        }),
        async manageTabs () {
            let resArray = []
            let firstSelected = false
            for (let i = 0; i < this.tabsStrapi.length; i++) {
                let nb = i === 1 ? await this.findNews()
                    : await this.findNbElements(this.tabsStrapi[i].attributes.name)
                resArray.push({
                    label: this.tabsStrapi[i].attributes.name,
                    nb: nb
                })
                if (nb && !firstSelected) {
                    this.currentTab = i + 1
                    firstSelected = true
                }
            }

            this.tabs = this.tabsStrapi.map(tab => {
                return {
                    label: tab.attributes.name,
                    dot: this.search && resArray.find(e => e.label === tab.attributes.name) && resArray.find(e => e.label === tab.attributes.name).nb
                }
            })
        },
        async findNbElements (category) {
            const res = await this.getMediaResources({ category: category.replace('&', '%26'), limit: 1, search: this.search, data: false })
            return res
        },
        async findNews () {
            const res = await this.getNews({ limit: 1, search: this.search, resource: true, data: false })
            return res
        }
    }
}
</script>

<style lang="scss" scoped>
.resources-section {
    background-color: $gray2;
}

.resources-content {
    width: 100%;
}
</style>
