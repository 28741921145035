<template>
    <custom-section
        id="anchor-media-library">
        <router-link :to="{ name: 'MediaLibraries'}">
            <Title :title=" $t('titles.media_library') " />
        </router-link>
        <tabs
            v-model="currentTab"
            :tabs="tabs" />

        <div class="libraries-content">
            <div
                v-if="currentTab === 1">
                <ArticlesList
                    :tab="true"
                    :article="false"
                    :image="true"
                    category="logos %26 images" />
            </div>
            <div
                v-if="currentTab === 2">
                <ArticlesList
                    :tab="true"
                    :article="false"
                    :video="true"
                    category="action shots" />
            </div>
            <div
                v-if="currentTab === 3">
                <ArticlesList
                    :tab="true"
                    :article="false"
                    :image="true"
                    category="event photos" />
            </div>
            <div
                v-if="currentTab === 4">
                <ArticlesList
                    :tab="true"
                    :article="false"
                    :video="true"
                    category="b-roll" />
            </div>
            <div
                v-if="currentTab === 5">
                <ArticlesList
                    :tab="true"
                    :article="false"
                    :image="true"
                    category="executive headshots bios" />
            </div>
        </div>
    </custom-section>
</template>

<script>
import CustomSection from '@/components/common/custom-section'
import Tabs from '@/components/common/custom-tabs'
import { mapActions } from 'vuex'
import Title from '@/components/common/title'
import ArticlesList from '@/components/articles/ArticlesList'

export default {
    name: 'MediaLibrary',
    components: { ArticlesList, Title, Tabs, CustomSection },
    data () {
        return {
            currentTab: 1,
            tabs: []
        }
    },
    computed: {
        tabsStrapi () {
            return this.$store.state.mediaLibrary.categories
        },
        search () {
            return this.$store.state.search
        },
        category () {
            return this.$store.state.mediaLibrary.category
        }
    },
    watch: {
        currentTab () {
            this.setCategory({ category: this.tabsStrapi[this.currentTab - 1] })
        },
        search: async function () {
            await this.manageTabs()
        },
        tabsStrapi: async function () {
            await this.manageTabs()
        }
    },
    async mounted () {
        this.getCategories()
        if (this.category) {
            this.currentTab = this.tabsStrapi.findIndex(tab => tab.id === this.category.id) + 1
        }
    },
    methods: {
        ...mapActions({
            getCategories: 'mediaLibrary/getCategories',
            setCategory: 'mediaLibrary/setCategory',
            getMediaLibraries: 'mediaLibrary/getMediaLibraries'
        }),
        async manageTabs () {
            let resArray = []
            let firstSelected = false
            for (let i = 0; i < this.tabsStrapi.length; i++) {
                let nb = await this.findNbElements(this.tabsStrapi[i].attributes.name)
                resArray.push({
                    label: this.tabsStrapi[i].attributes.name,
                    nb: nb
                })
                if (nb && !firstSelected) {
                    this.currentTab = i + 1
                    firstSelected = true
                }
            }

            this.tabs = this.tabsStrapi.map(tab => {
                return {
                    label: tab.attributes.name,
                    dot: this.search && resArray.find(e => e.label === tab.attributes.name) && resArray.find(e => e.label === tab.attributes.name).nb
                }
            })
        },
        async findNbElements (category) {
            const res = await this.getMediaLibraries({ category: category.replace('&', '%26'), limit: 1, search: this.search, data: false })
            return res
        }
    }
}
</script>

<style lang="scss" scoped>
.libraries-content {
    width: 100%;
}
</style>
