<template>
    <div class="image-download">
        <div class="image">
            <img
                v-tilt
                :src="getMedia(image)" />
        </div>

        <div class="content">
            <div class="title">
                {{ title }}
            </div>

            <div class="download">
                <a
                    href="#"
                    @click.prevent="
                        downloadItem()
                    ">
                    <img src="../../static/icons/download.png" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { getMedia } from '@/utils/common'
import axios from 'axios'
import { strapiBase } from '@/utils/environment'

export default {
    props: {
        title: { type: String, default: '' },
        image: { type: Object },
        file: { type: Object }
    },
    computed: {

    },
    methods: {
        getMedia (image) {
            return getMedia(image)
        },
        async downloadItem () {
            const media = this.file.data ? this.file : this.image
            const response = await axios.get(media.data.attributes.url, { baseURL: strapiBase, responseType: 'blob' })
            const blob = new Blob([response.data], { type: media.data.attributes.mime })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = media.data.attributes.name
            link.click()
            URL.revokeObjectURL(link.href)
        }
    }
}
</script>

<style lang="scss" scoped>
.image-download {
    margin: 20px;
    @include breakpoint($max: mobile) {
        width: 100%;
        margin: 0px;
        margin-bottom: 20px;
    }
    @include flex(column, flex-start, center);
    .image {
        width: 360px;
        @include breakpoint($max: mobile) {
            width: 100%;
        }
        img {
            width: 100%;
            border-radius: 10px;
        }

    }
    .content {
        width: 100%;
        margin-top: 20px;
        @include flex(row, center, space-between);

        .title {
            font-weight: bolder;
            font-size: 20px;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 300px;
        }
        .download {
            width: 34px;
            img {
                width: 100%;
            }
        }
    }
}
</style>
