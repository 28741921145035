<template>
    <div class="form-container">
        <custom-input
            v-model="name"
            class="input"
            placeholder="Name" />
        <custom-input
            v-model="email"
            class="input"
            placeholder="Email" />
        <custom-input
            v-model="object"
            class="input"
            placeholder="Object" />
        <custom-textarea
            v-model="content"
            :maxlength="500"
            class="input"
            placeholder="Content" />
        <custom-button
            @click.native="validateFormDebounce">
            {{ $t('common.send') }}
        </custom-button>
    </div>
</template>

<script>
import CustomInput from '@/components/common/custom-input'
import { mapActions } from 'vuex'
import CustomButton from '@/components/press/CustomButton'
import CustomTextarea from '@/components/common/custom-textarea'
import _ from 'debounce'

export default {
    name: 'ContactForm',
    components: { CustomTextarea, CustomButton, CustomInput },
    props: {
        type: { type: String, default: 'TSB' }
    },
    data () {
        return {
            email: '',
            name: '',
            object: '',
            content: ''
        }
    },
    methods: {
        ...mapActions({
            sendContact: 'sendContact'
        }),
        resetForm () {
            this.email = ''
            this.object = ''
            this.name = ''
            this.content = ''
        },
        async validateForm () {
            let response = await this.sendContact({
                email: this.email,
                object: this.object,
                name: this.name,
                content: this.content
            })

            if (response.status === 200) {
                this.resetForm()
                // send mail
            }
        },
        validateFormDebounce: _.debounce(function () {
            this.validateForm()
        }, 1000)
    }
}
</script>

<style lang="scss" scoped>

.form-container {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    @include breakpoint($device: mobile) {
        width: 100%;
        @include flex(column, center, center)
    }

    .input {
        width: 100%;
        margin-bottom: 12px;
    }
}
</style>
