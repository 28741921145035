<template>
    <default-layout>
        <Article />
    </default-layout>
</template>

<script>

import Article from '@/components/articles/Article'
import DefaultLayout from '@/layout/default'

export default {
    name: 'ArticlePage',
    components: {
        DefaultLayout,
        Article
    }
}
</script>
