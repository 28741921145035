<template>
    <div class="article">
        <img :src="getMedia(image)" />

        <div class="content">
            <div class="news-title">
                {{ title }}
            </div>
            <p class="is-text-semi">
                {{
                    description.length > 100
                        ? description.substring(0, 100) + '...'
                        : description
                }}
            </p>
            <div class="date">
                {{ date | moment("LL") }}
            </div>
        </div>
    </div>
</template>

<script>
import { getMedia } from '@/utils/common'

export default {
    props: {
        title: { type: String, default: '' },
        image: { type: Object },
        description: { type: String, default: '' },
        date: { type: String, default: '' }
    },
    computed: {

    },
    methods: {
        getMedia (image) {
            return getMedia(image)
        }
    }
}
</script>

<style lang="scss" scoped>
.article {
    width: 360px;
    @include breakpoint($device: mobile) {
        width: 100%;
        margin: 0px;
        margin-bottom: 20px;
    }
    margin: 20px;
    margin-bottom: 60px;
    .content {
        @include flex(column, flex-start, space-around);
        text-align: left;
        padding: 0px;
        max-height: 200px;
        .news-title {
            padding-top: 20px;
            font-weight: bolder;
            font-size: 20px;
        }
        .date {
            align-self: flex-end;
            font-style: italic;
        }
    }

    img {
        border-radius: 10px;
        margin: 0;
        width: 100%;
        height: auto;
        background-size: cover;
    }
    transition: all 0.25s ease;
    cursor: pointer;
    &:hover {
        transform: scale(1.05);
    }
}
</style>
