<template>
    <div class="d-flex justify-content-around flex-column footer">
        <div class="links-container">
            <div class="links">
                <a
                    href="https://www.sandbox.game/"
                    target="_blank"
                    class="col-sm-3 footer-section logo">
                    <img
                        src="../static/svg/TSBLogo.svg"
                        alt="Logo Sandbox"
                        title="Sandbox" />
                </a>
                <div class="col-sm-3 pages-section d-flex flex-wrap footer-section">
                    <div class="col-12 title text-left mb-3">
                        {{ this.$t('footer.pages.title') }}
                    </div>
                    <div
                        v-for="(link, i) in this.$t('footer.pages.pages')"
                        :key="i"
                        class="col-6 text-left">
                        <a
                            :href="link.link"
                            class="footer-link"
                            target="_blank">
                            {{ link.title }}
                        </a>
                    </div>
                </div>
                <div
                    v-if="socialIcons.length"
                    class="col-sm-3 footer-section social-section d-flex flex-wrap">
                    <div class="col-12 title text-left">
                        {{ this.$t('footer.social') }}
                    </div>
                    <a
                        v-for="(icon, i) in socialIcons"
                        :key="i"
                        :class="icon.name"
                        :href="icon.url"
                        class="social-icon" />
                </div>
            </div>
        </div>
        <div class="copyright">
            Copyright © 2012-2022, The Sandbox. All Rights Reserved.
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    data () {
        return {
            socialIcons: this.$t('footer.socialIcons')
        }
    }
}
</script>

<style lang="scss" scoped>
    $sandbox-logo: url('../static/img/TSBLogo.png');
    $icon-discord: url('../static/svg/DiscordLogo.svg');
    $icon-telegram: url('../static/svg/TelegramLogo.svg');
    $icon-medium: url('../static/svg/MediumLogo.svg');
    $icon-twitter: url('../static/svg/TwitterLogo.svg');
    $icon-instagram: url('../static/svg/InstagramLogo.svg');
    $icon-facebook: url('../static/svg/FacebookLogo.svg');
    $icon-twitch: url('../static/svg/TwitchLogo.svg');
    $icon-youtube: url('../static/svg/YouTubeLogo.svg');
    $icon-reddit: url('../static/svg/RedditLogo.svg');

    .footer-section {
        min-height: 13em;
    }
    .links-container {
        background-color: $grey-2;
        min-height: 420px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .links {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .copyright {
        background: $grey-1;
        color: $grey-6;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
    }
    .title {
        font-weight: bold;
        font-size: 1.73em;
        color: $white-1;
        text-transform: uppercase;
    }
    .footer-link {
        color: $grey-7;
        font-weight: 500;
        text-transform: capitalize;
        margin-bottom: .5em;
        &:hover {
            cursor: pointer;
        }
        &.active {
            color: $white-1;
        }
    }
    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .social-icon {
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: cover !important;
        height: 60px;
        width: 60px;
        margin: .8em;
        &:hover {
            cursor: pointer;
        }
        &.discord {
            background: $icon-discord;
        }
        &.telegram {
            background: $icon-telegram;
        }
        &.medium {
            background: $icon-medium;
        }
        &.twitter {
            background: $icon-twitter;
        }
        &.instagram {
            background: $icon-instagram;
        }
        &.facebook {
            background: $icon-facebook;
        }
        &.twitch {
            background: $icon-twitch;
        }
        &.youtube {
            background: $icon-youtube;
        }
        &.reddit {
            background: $icon-reddit;
        }
    }
    .social-section {
        max-width: 365px;
    }
    .pages-section {
        min-width: 420px;
        @media (max-width: 1120px) {
            min-width: 330px;
        }
    }
    @media screen and (max-width: 1130px) {
        .links,
        .links-container {
            flex-direction: column;
        }
        .links-container {
            padding-bottom: 65px;
        }
        .social-section {
            min-width: auto;
        }
        .social-section {
            margin-top: 20px;
            justify-content: center;
        }
        .title, .pages-section > div {
            text-align: center !important;
        }
        .logo {
            margin: 0;
        }
    }
</style>
