<template>
    <div
        id="anchor-search"
        class="search-section">
        <div class="input-container">
            <custom-input
                v-model="searchText"
                class="input"
                :image="true"
                placeholder="Search"
                @enter="validate"
                @search="validate" />
        </div>
    </div>
</template>

<script>
import CustomInput from '@/components/common/custom-input'
import { mapActions } from 'vuex'

export default {
    name: 'SearchInput',
    components: { CustomInput },
    data () {
        return {
            searchText: ''
        }
    },
    computed: {
        search () {
            return this.$store.state.search
        }
    },
    mounted () {
        if (this.$route.query.s) {
            this.searchText = this.$route.query.s
            this.setSearch({ search: this.searchText })
        } else if (this.search) {
            this.searchText = this.search
            this.setSearch({ search: this.searchText })
        }
        if (this.$route.name === 'Home') {
            this.setSearch({ search: '' })
        }
    },
    methods: {
        ...mapActions({
            setSearch: 'setSearch'
        }),
        validate: function () {
            this.setSearch({ search: this.searchText })
            if (this.$route.name === 'Home') {
                this.$router.push({ name: 'Search', query: { s: this.searchText } })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.search-section {
    width: 100%;
    background-color: $gray2;
    padding-top: 40px;
}

.input-container {
    margin-left: auto;
    margin-right: auto;
    width: 450px;
    height: 50px;
    @include breakpoint($device: mobile) {
        width: 90%;
    }
}

.content {
    text-align: center;
}

.input {
    align-self: center;
}
</style>
