import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import Landing from '@/pages/Landing.vue'
import PressReleases from '@/pages/PressReleases.vue'
import News from '@/pages/News.vue'
import Article from '@/pages/Article.vue'
import MediaLibraries from '@/pages/MediaLibraries.vue'
import Search from '@/pages/Search.vue'
import i18n from '../i18n.js'
import MediaResources from '@/pages/MediaResources.vue'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routesList = [
    {
        'name': 'Home',
        'path': ''
    }
]

const children = routesList.map(({ name, path, favicon }) => ({
    name: name,
    path: path,
    meta: {
        title: 'meta.title',
        description: 'meta.desc',
        icon: '/favicon.ico'
    }
}
))

const routes = [
    {
        path: '*',
        redirect: { name: 'Home' }
    },
    {
        path: '/:lang',
        component: Landing,
        children: children,
        name: 'Home'
    },
    {
        path: '/:lang/press_releases',
        component: PressReleases,
        name: 'PressReleases'
    },
    {
        path: '/:lang/news',
        component: News,
        name: 'News'
    },
    {
        path: '/:lang/media_libraries',
        component: MediaLibraries,
        name: 'MediaLibraries'
    },
    {
        path: '/:lang/media_resources',
        component: MediaResources,
        name: 'MediaResources'
    },
    {
        path: '/:lang/press_releases/:id',
        component: Article,
        name: 'PressRelease'
    },
    {
        path: '/:lang/news/:id',
        component: Article,
        name: 'OneNews'
    },
    /*    {
        path: '/:lang/media_libraries/:id',
        component: Article,
        name: 'MediaLibrary'
    }, */
    {
        path: '/:lang/media_resources/:id',
        component: Article,
        name: 'MediaResource'
    },
    {
        path: '/:lang/search',
        component: Search,
        name: 'Search'
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior (to) {
        if (!to.hash) {
            return { x: 0, y: 0 }
        }
    },
    routes
})

router.beforeEach((to, from, next) => {
    let language = to.params.lang
    let url = to.fullPath.split('/')
    if (url.length < 3) {
        if (!Object.keys(i18n.messages).includes(language)) {
            return next(i18n.locale + '/' + url[1])
        } else {
            return next(language + '/' + url[1])
        }
    } else {
        if (!Object.keys(i18n.messages).includes(language)) {
            return next('en/' + url[2])
        }
        if (!language) {
            language = 'en'
        }

        i18n.locale = language
        next()
    }
})

export default router
