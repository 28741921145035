<template>
    <div class="custom-input">
        <img
            v-if="image"
            class="image"
            src="../../static/icons/search.svg"
            @click="$emit('search')" />
        <input
            :value="value"
            :placeholder="placeholder"
            :type="type"
            :autocomplete="autocomplete"
            :disabled="disabled"
            :class="{
                error,
                correct,
                textAlignLeft,
                textAlignRight,
                textAlignCenter,
                dark
            }"
            :maxlength="maxlength"
            :required="required"
            @input="$emit('input', $event.target.value)"
            @keyup.enter="$emit('enter')"
            @focus="$emit('focus')"
            @blur="$emit('blur')"
            v-on="listeners" />
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        value: { type: [String, Number], default: '' },
        placeholder: { type: String, default: '' },
        type: { type: String, default: 'text' },
        disabled: { type: Boolean },
        error: { type: Boolean },
        correct: { type: Boolean },
        maxlength: { type: [Number, String], default: 150 },
        required: { type: Boolean, default: false },
        autocomplete: { type: String, default: 'off' },
        textAlignLeft: { type: Boolean, default: false },
        textAlignRight: { type: Boolean, default: false },
        textAlignCenter: { type: Boolean, default: false },
        label: { type: String, default: '' },
        dark: { type: Boolean, default: false },
        image: { type: Boolean, default: false }
    },
    computed: {
        listeners () {
            // eslint-disable-next-line no-unused-vars
            const { input, ...listeners } = this.$listeners
            return listeners
        }
    }
}
</script>

<style lang="scss" scoped>
.custom-input {
    position: relative;

    .image {
        position: absolute;
        top: 14px;
        right: 20px;
    }

    input {
        width: 100%;
        height: 44px;

        color: white;
        font-size: 16px;
        line-height: 40px;

        background-color: $gray4;
        border: solid;
        border-color: $gray7;
        border-width: 2px;
        border-radius: 20px;
        outline: none !important;

        margin-bottom: 6px;
        padding: 0 15px 2px 15px;

        &::placeholder {
            color: $gray7;
        }
        &:hover {
            &:not([disabled]) {
                border-color: $gray8;
            }
        }
        &.correct {
            border-color: lightseagreen;
        }
        &:focus {
            border-color: $gray8 !important;
        }
        &.error {
            border-color: lightcoral;
        }
        &[disabled] {
            color: $gray7;
        }
        &.textAlignLeft {
            text-align: left;
        }
        &.textAlignRight {
            text-align: right;
        }
        &.textAlignCenter {
            text-align: center;
        }
        &.dark {
            background-color: $gray2;
        }
    }
}
</style>
