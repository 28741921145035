<template>
    <div v-if="articles.length">
        <div class="articles-container">
            <div
                v-for="(elem, i) in articles"
                :key="i"
                class="article is-cursor">
                <router-link :to="routerLink(elem)">
                    <content-summary
                        v-if="resource && summary && article"
                        :image="elem.attributes.image"
                        :title="elem.attributes.title"
                        :description="elem.attributes.description"
                        :date="elem.attributes.publishedAt" />
                    <content-vertical
                        v-else-if="resource && !summary && article"
                        :image="elem.attributes.image"
                        :title="elem.attributes.title"
                        :description="elem.attributes.description"
                        :date="elem.attributes.publishedAt" />
                </router-link>
                <content-image
                    v-if="image && !article"
                    :image="elem.attributes.image"
                    :title="elem.attributes.title" />
                <content-video
                    v-if="video && !article"
                    :image="elem.attributes.image"
                    :video="elem.attributes.video"
                    :title="elem.attributes.title" />
            </div>
        </div>
        <router-link
            v-if="tab && isHome && total > 0"
            :to="{ name: 'MediaResources'}"
            class="load-more">
            {{ $t('common.view_more') }}
        </router-link>
        <div
            v-else-if="!(tab && isHome) && limit < total"
            class="load-more"
            @click="loadMore">
            {{ $t('common.load_more') }}
        </div>
    </div>
    <div v-else>
        {{ $t('common.no_content') }}
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import ContentVertical from '@/components/common/content-vertical'
import ContentSummary from '@/components/common/content-summary'
import ContentImage from '@/components/common/content-image'
import ContentVideo from '@/components/common/content-video'

export default {
    name: 'ArticlesList',
    components: { ContentVideo, ContentImage, ContentSummary, ContentVertical },
    props: {
        tab: { type: Boolean, default: false },
        category: { type: String, default: '' },
        summary: { type: Boolean, default: false },
        resource: { type: Boolean, default: false },
        article: { type: Boolean, default: true },
        image: { type: Boolean, default: false },
        video: { type: Boolean, default: false }
    },
    data () {
        return {
            articles: [],
            limit: 3,
            step: 3
        }
    },
    computed: {
        total () {
            return this.resource ? this.$store.state.mediaResource.total : this.$store.state.mediaLibrary.total
        },
        isHome () {
            return this.$router.currentRoute.name === 'Home'
        },
        search () {
            return this.$store.state.search
        }
    },
    watch: {
        async category () {
            await this.getArticles()
        },
        async search () {
            await this.getArticles()
        }
    },
    async mounted () {
        await this.getArticles()
    },
    methods: {
        ...mapActions({
            getCategories: 'mediaResource/getCategories',
            getMediaResources: 'mediaResource/getMediaResources',
            getMediaLibraries: 'mediaLibrary/getMediaLibraries',
            getNews: 'news/getNews'
        }),
        setLimit () {
            if (this.$router.currentRoute.name === 'MediaResources' || this.$router.currentRoute.name === 'MediaLibraries') {
                this.limit = 9
                this.step = 9
            } else {
                if (this.summary) {
                    this.limit = 1
                } else {
                    this.limit = 3
                }
            }
        },
        routerLink (elem) {
            return { name: this.resource ? 'MediaResource' : 'MediaLibrary', params: { id: elem.id } }
        },
        async getArticles () {
            this.setLimit()
            if (this.resource) {
                if (this.category && this.category !== 'news') {
                    this.articles = await this.getMediaResources({ category: this.category, limit: this.limit, search: this.search })
                } else {
                    this.articles = await this.getNews({ limit: this.limit, search: this.search, resource: true })
                }
            } else {
                this.articles = await this.getMediaLibraries({ category: this.category, limit: this.limit, search: this.search })
            }
        },
        async loadMore () {
            this.limit += this.step
            await this.getArticles()
        }
    }
}
</script>

<style lang="scss" scoped>
.load-more {
    align-self: center;
    font-size: 18px;
    font-style: italic;
    transition: 200ms;

    &:hover {
        text-decoration: none;
        cursor: pointer;
    }
}

.articles-container {
    width: 100%;
    @include flex(row, center, flex-start);
    flex-wrap: wrap;
    @include breakpoint($device: tablet) {
        @include flex(row, center, center);
    }
}

.article {
    @include breakpoint($device: mobile) {
        width: 100%;
    }
}
</style>
