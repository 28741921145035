<template>
    <default-layout>
        <SearchInput />
        <News />
        <PressRelease />
        <MediaResources />
        <MediaLibrary />
        <Partners />
    </default-layout>
</template>

<script>

import PressRelease from '@/components/PressReleaseSection.vue'
import MediaLibrary from '@/components/MediaLibrarySection.vue'
import MediaResources from '@/components/MediaResourceSection.vue'
import Partners from '@/components/Partners.vue'
import SearchInput from '@/components/SearchInput'
import News from '@/components/News'
import DefaultLayout from '@/layout/default'
import { capitalizeFirstLetter } from '@/utils/common'

export default {
    name: 'Search',
    components: {
        DefaultLayout,
        News,
        SearchInput,
        PressRelease,
        MediaLibrary,
        MediaResources,
        Partners
    },
    metaInfo: function () {
        return {
            title: capitalizeFirstLetter(this.$t('press.topSection.search')),
            meta: [
                { name: 'description', content: '' }
            ]
        }
    },
    data: function () {
        return {
        }
    }
}
</script>
