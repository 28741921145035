<template>
    <custom-section
        id="anchor-news"
        class="news-section">
        <router-link
            :to="{ name: 'News'}">
            <Title :title="$t('titles.news')" />
        </router-link>

        <div v-if="articles.length">
            <div class="articles-container">
                <div
                    v-for="(elem, i) in articles"
                    :key="i"
                    class="article is-cursor">
                    <router-link :to="{ name: 'OneNews', params: {id: elem.id}}">
                        <content-vertical
                            class="vertical"
                            :image="elem.attributes.image"
                            :title="elem.attributes.title"
                            :description="elem.attributes.description"
                            :date="elem.attributes.publishedAt" />
                    </router-link>
                </div>
            </div>
            <div
                v-if="limit < total"
                class="load-more"
                @click="loadMore">
                {{ $t('common.load_more') }}
            </div>
        </div>
        <div v-else>
            {{ $t('common.no_content') }}
        </div>
    </custom-section>
</template>

<script>
import CustomSection from '@/components/common/custom-section'
import { mapActions } from 'vuex'
import Title from '@/components/common/title'
import ContentVertical from '@/components/common/content-vertical'

export default {
    name: 'News',
    components: { ContentVertical, Title, CustomSection },
    data () {
        return {
            articles: [],
            limit: 3,
            step: 3
        }
    },
    computed: {
        total () {
            return this.$store.state.news.total
        },
        search () {
            return this.$store.state.search
        }
    },
    watch: {
        async search () {
            await this.getArticles()
        }
    },
    async mounted () {
        if (this.$router.currentRoute.name === 'News') {
            this.limit = 9
            this.step = 9
        }
        await this.getArticles()
    },
    methods: {
        ...mapActions({
            getNews: 'news/getNews'
        }),
        async getArticles () {
            this.articles = await this.getNews({ limit: this.limit, search: this.search })
        },
        async loadMore () {
            this.limit += this.step
            await this.getArticles()
        }
    }
}
</script>

<style lang="scss" scoped>
.news-section {
    background-color: $gray2;
}

.load-more {
    align-self: center;
    font-weight: bold;
    font-size: 18px;
    transition: 200ms;

    &:hover {
        text-decoration: none;
        cursor: pointer;
    }
}

.articles-container {
    @include flex(row, center, space-around);
    flex-wrap: wrap;
    @include breakpoint($device: mobile) {
        align-self: center;
    }
}
</style>
