import PressService from '@/services/PressService'

const MUTATION_TYPES = {
    SET_PARTNERS: 'SET_PARTNERS'
}

export default ({
    namespaced: true,
    state: {
        partners: []
    },
    mutations: {
        [MUTATION_TYPES.SET_PARTNERS]: (state, partners) => {
            state.partners = partners
        }
    },
    actions: {
        async getPartners ({ commit }) {
            let result = await PressService.getPartners()
            commit(MUTATION_TYPES.SET_PARTNERS, result.data)
        }
    },
    modules: {
    }
})
