<template>
    <default-layout>
        <Header />
        <Banner />
        <Quotes />
        <SearchInput />
        <News />
        <PressRelease />
        <MediaResources />
        <MediaLibrary />
        <Partners />
        <Contact />
    </default-layout>
</template>

<script>
import Header from '@/components/Header.vue'
import Banner from '@/components/Banner.vue'
import News from '@/components/News.vue'
import Quotes from '@/components/Quotes.vue'
import PressRelease from '@/components/PressReleaseSection.vue'
import MediaLibrary from '@/components/MediaLibrarySection.vue'
import MediaResources from '@/components/MediaResourceSection.vue'
import Partners from '@/components/Partners.vue'
import SearchInput from '@/components/SearchInput'
import Contact from '@/components/Contact'
import DefaultLayout from '@/layout/default'

export default {
    name: 'Landing',
    components: {
        DefaultLayout,
        Contact,
        SearchInput,
        Header,
        Banner,
        PressRelease,
        MediaLibrary,
        MediaResources,
        News,
        Quotes,
        Partners
    },
    data: function () {
        return {
            reload: false
        }
    },
    watch: {
        '$route.hash': {
            handler: function (hash) {
                if (hash) {
                    let self = this
                    let done = this.scrollToHash(hash)

                    if (!done) {
                        // reload
                        window.addEventListener('DOMContentLoaded', (event) => {
                            self.reload = true
                            setTimeout(() => {
                                self.scrollToHash(hash)
                            }, 1500)
                        })

                        // switch page
                        if (!this.reload) {
                            setTimeout(() => {
                                self.scrollToHash(hash)
                            }, 500)
                        }
                    }
                }
            },
            deep: true,
            immediate: true
        }
    },
    async mounted () {

    },
    methods: {
        scrollToHash (hash) {
            const element = document.querySelector(hash)
            if (!element) return false
            let topPos = element.offsetTop

            window.scrollTo({
                top: topPos - 40,
                behavior: 'smooth'
            })
        }
    }
}
</script>
