<template>
    <div class="main-header-landing">
        <nav class="navbar navbar-expand-lg items-container navbar-dark">
            <div class="logo-container">
                <div class="burger-container">
                    <div
                        class="burger"
                        :class="{ opened: burgerState }"
                        @click="burgerState = !burgerState">
                        <span />
                        <span />
                        <span />
                    </div>
                </div>
                <a
                    href="https://www.sandbox.game/"
                    target="_blank"
                    class="navbar-brand ml-1">
                    <img
                        src="../static/svg/TSBLogo.svg"
                        alt="Logo Sandbox"
                        title="Sandbox"
                        class="logo" />
                </a>
            </div>

            <div :class="['links-header', { 'links-header--opened': burgerState }]">
                <router-link
                    :to="{ name: 'Home', hash: '#anchor-news' }"
                    class="link">
                    {{ $t("press.topSection.news") }}
                </router-link>
                <router-link
                    :to="{ name: 'Home', hash: '#anchor-press-release' }"
                    class="link">
                    {{ $t("press.topSection.pressRelease") }}
                </router-link>
                <router-link
                    :to="{ name: 'Home', hash: '#anchor-media-resources' }"
                    class="link">
                    {{ $t("press.topSection.resources") }}
                </router-link>
                <router-link
                    :to="{ name: 'Home', hash: '#anchor-media-library' }"
                    class="link">
                    {{ $t("press.topSection.media") }}
                </router-link>
                <router-link
                    :to="{ name: 'Home', hash: '#anchor-contact' }"
                    class="link">
                    {{ $t("press.topSection.contact") }}
                </router-link>
                <router-link
                    :to="{ name: 'Home', hash: '#anchor-search' }"
                    class="link">
                    {{ $t("press.topSection.search") }}
                </router-link>
            </div>

            <div class="d-flex mr-1">
                <div
                    v-click-outside="() => langSelectorDropdown = false"
                    class="lang-selector"
                    :class="{'opened': langSelectorDropdown}"
                    @click="langSelectorDropdown = !langSelectorDropdown">
                    <span class="actual-lang">
                        {{ $i18n.locale }}
                    </span>
                    <div
                        :class="{'hide': !langSelectorDropdown}"
                        :style="langSelectorDropdown ? `max-height: ${3 * langSelector.length}em;` : ''"
                        class="lang-selector-dropdown">
                        <div
                            v-for="(lang, k) in langSelector"
                            :key="k"
                            class="item"
                            :class="{'selected': $i18n.locale == lang}"
                            @click="changeLocale(lang)">
                            {{ lang }}
                        </div>
                    </div>
                </div>

                <button
                    v-if="this.$t(this.$route.name + '.navbar') !== this.$route.name + '.navbar' && this.$t(this.$route.name + '.navbar').length"
                    class="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarToggler"
                    aria-controls="navbarToggler"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon" />
                </button>
            </div>
        </nav>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    name: 'Header',
    data () {
        return {
            langSelector: Object.keys(this.$i18n.messages),
            langSelectorDropdown: false,
            burgerState: false
        }
    },
    methods: {
        changeLocale (lang) {
            this.$router.replace({ name: this.$route.name, params: { lang: lang } }).catch(() => {})
            if (lang === 'cn') {
                lang = 'zh-cn'
            }

            moment.locale(lang)
        }
    }
}
</script>

<style lang="scss">
    .hideme { display: none !important; }
    $header-height: 4em;
    $lang-selector-height: 2.6em;
    $main-logo: url('../static/svg/VoxEditLogo.svg');
    $icon-chevron: url('../static/svg/ArrowDropdown.svg');

    .burger-container {
        width: 20px;
        height: 14px;
        position: relative;
        display: none;
        @include breakpoint($max: 930) {
            display: block;
        }
        @include breakpoint($device: tablet) {
            width: 42px;
            height: 30px;
        }
        .burger {
            transition: all 0.25s ease;
            @include breakpoint($max: tablet) {
                display: flex;
            }
            span {
                height: 2px;
                display: block;
                width: 100%;
                border-radius: 2px;
                background-color: #fff !important;
                position: absolute;
                transition: all 0.25s ease;
                @include breakpoint($device: tablet) {
                    height: 4px;
                }
                &:nth-child(2) {
                    top: 6px;
                    @include breakpoint($device: tablet) {
                    top: 13px;
                    }
                }
                &:last-child {
                    top: 12px;
                    @include breakpoint($device: tablet) {
                    top: 26px;
                    }
                }
            }

            &.opened {
                span {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    margin-top: -1px;
                    display: block;
                    width: 100%;

                    &:first-child {
                        transform: rotate(45deg);
                    }
                    &:last-child {
                        transform: rotate(-45deg);
                    }
                    &:nth-child(2) {
                        opacity: 0;
                    }
                }
            }
        }
    }

    .logo-container {
        @include flex(row, center, center);
        margin-left: 10px;
    }

    .main-header-landing {
        position: fixed;
        width: 100%;
        top: 0;
        align-items: center;
        background: $grey-1;
        color: $grey-7;
        display: flex;
        font-weight: bold;
        height: $header-height;
        justify-content: space-between;
        z-index: 99;
    }

    .main-header-landing .header-link {
        color: inherit;
        text-decoration: none;
        height: $header-height;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            color: inherit;
            text-decoration: none;
        }
    }

    .main-header-landing .navbar-item {
        align-items: center;
        display: flex;
        justify-content: center;
        transition: all .5s ease;
        user-select: none;
        font-weight: 600;
        font-size: 17px;
        margin-right: 45px;
        &:hover {
            cursor: pointer;
            filter: brightness(125%);
        }
        &>.has-dropdown {
            height: 100% !important;
        }
        &>.has-dropdown .fa-angle-down {
            transition: all .2s ease;
        }
        &>.has-dropdown.opened .fa-angle-down {
            transform: rotateZ(-180deg);
        }
    }
    .main-header-landing .navbar-dropdown {
        background: darken($grey-3, 5);
        border-bottom-left-radius: 1em;
        border-bottom-right-radius: 1em;
        overflow: hidden;
        position: absolute;
        top: 60px;
        transition: max-height .2s ease;
        width: fit-content;
        z-index: 99999;
        &.hide {
            max-height: 0;
        }
        .item {
            align-items: center;
            display: flex;
            justify-content: center;
            min-height: 3em;
            min-width: 10em;
            transition: all .2s ease;
            &:hover {
                cursor: pointer;
                background: darken($grey-3, 7);
            }
        }
        .header-link {
            height: 100%;
        }
    }

    .main-header-landing .lang-selector {
        display: none;
        background: $grey-3;
        border-radius: .8em;
        height: $lang-selector-height;
        align-items: center;
        justify-content: space-around;
        letter-spacing: 0;
        color: $white-1;
        opacity: 1;
        text-transform: uppercase;
        user-select: none;
        position: relative;
        width: 100px;
        padding: 9px;
        &:hover {
            cursor: pointer;
        }
        &::after {
            content: '';
            position: absolute;
            right: 10px;
            top: 16px;
            background: $icon-chevron;
            background-repeat: no-repeat;
            height: 1em;
            width: 1em;
            transition: all .2s ease;
        }
        &.opened::after {
            transform: rotateZ(-180deg);
            top: 9px;
        }
    }
    .main-header-landing .lang-selector-dropdown {
        position: absolute;
        top: 2.6em;
        left: 10px;
        border-bottom-left-radius: .8em;
        border-bottom-right-radius: .8em;
        z-index: 10;
        overflow: hidden;
        background: darken($grey-3, 5);
        transition: max-height .3s ease;
        &.hide {
            max-height: 0;
        }
        .item {
            min-width: 5.3em;
            min-height: 2.5em;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
                cursor: pointer;
                background: darken($grey-3, 7);
            }
        }
    }
    .main-header-landing .logo {
        height: 100%;
        margin-right: .5em;
    }
    .main-header-landing .actual-lang {
        font-size: 15px;
        font-weight: 600;
        margin-right: 25px;
    }
    .main-header-landing .items-container {
        height: $header-height;
    }
    .main-header-landing .navbar-toggler {
        margin: 0 10px;
    }
    .main-header-landing .navbar-collapse, .main-header-landing .navbar-collapse > ul {
        height: $header-height;
    }
    .main-header-landing .navbar-brand {
        margin-right: 0;
    }
    .main-header-landing .navbar{
        margin: auto;
        width: 100%;
        max-width: 1470px;
        height: $header-height;
        @include flex(row, center, space-between);
    }

    .links-header {
        @include flex(row, center, center);
        transition: 200ms;
        a {
            text-transform: uppercase;
            color: white;
            margin: 0px 10px;
            font-size: 20px;
            transition: 100ms;
        }
        @media (max-width: 1240px) {
            a {
                font-size: 15px;
                margin: 0px 5px;
            }
        }
        @media (max-width: 930px) {
            position: fixed;
            @include flex(column, flex-start, flex-start);
            top: 4em;
            left: 0;
            padding: 20px;
            height: calc(100vh - 4em);
            background-color: $grey-1;
            transform: translateX(-100%);
            a {
                margin: 5px 5px;
                margin-left: 0px;
            }
        }
        &--opened {
            transform: translateX(0);
        }
    }

    @media screen and (max-width: 991px) {
        .main-header-landing .navbar-brand{
            padding: 0 !important;
        }
        .main-header-landing .navbar-collapse > ul{
            background: $grey-1;
            color: $grey-7;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            z-index: 9;
            height: auto;
        }
        .main-header-landing .navbar-dropdown {
            position: static !important;
            display: block;
            width: 100%;
            border-radius: 0;
        }
        .main-header-landing .navbar-collapse, .main-header-landing .collapsing {
            position: absolute !important;
            top: $header-height;
            height: auto;
            width: 100%;
        }
        .main-header-landing .logo {
            margin-right: 0;
            margin-left: 10px;
        }
        .main-header-landing .navbar-item {
            margin-right: 0;
            height: 100%;
            width: 100%;
            padding: 15px 0;

            &>.has-dropdown {
                flex-direction: column;
                height: 100%;
            }
        }
        .main-header-landing .navbar{
            padding: 0 !important;
        }
        .main-header-landing .flag-container {
            margin: 0;
        }
    }
</style>
