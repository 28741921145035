<template>
    <div class="section">
        <div class="content">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'CustomSection'
}
</script>

<style lang="scss" scoped>
.section {
    background-color: $gray3;
    padding-bottom: 40px;

    @include breakpoint($device: mobile) {
        padding-bottom: 20px;
    }
}

.content {
    @include flex(column, flex-start, center);
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
    padding: 0px 20px ;
}
</style>
