<template>
    <default-layout>
        <search-input v-if="search" />
        <MediaResourcesSection />
    </default-layout>
</template>

<script>

import MediaResourcesSection from '@/components/MediaResourceSection'
import { capitalizeFirstLetter } from '@/utils/common'
import DefaultLayout from '@/layout/default'
import SearchInput from '@/components/SearchInput'

export default {
    name: 'MediaResources',
    components: {
        SearchInput,
        DefaultLayout,
        MediaResourcesSection

    },
    metaInfo: function () {
        return {
            title: capitalizeFirstLetter(this.$t('titles.media_resources')),
            meta: [
                { name: 'description', content: '' }
            ]
        }
    },
    computed: {
        search () {
            return this.$store.state.search
        }
    }
}
</script>
