var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"custom-input"},[(_vm.image)?_c('img',{staticClass:"image",attrs:{"src":require("../../static/icons/search.svg")},on:{"click":function($event){return _vm.$emit('search')}}}):_vm._e(),_c('input',_vm._g({class:{
            error: _vm.error,
            correct: _vm.correct,
            textAlignLeft: _vm.textAlignLeft,
            textAlignRight: _vm.textAlignRight,
            textAlignCenter: _vm.textAlignCenter,
            dark: _vm.dark
        },attrs:{"placeholder":_vm.placeholder,"type":_vm.type,"autocomplete":_vm.autocomplete,"disabled":_vm.disabled,"maxlength":_vm.maxlength,"required":_vm.required},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('enter')},"focus":function($event){return _vm.$emit('focus')},"blur":function($event){return _vm.$emit('blur')}}},_vm.listeners)),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }