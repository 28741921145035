<template>
    <div
        class="container-banner"
        :style="[
            { backgroundImage: `url(${getBg()})` }
        ]">
        <h1>{{ this.$t('banner.title') }}</h1>
    </div>
</template>

<script>
export default {
    name: 'Banner',
    methods: {
        getBg () {
            return require('../static/img/banner/description-banner.png')
        }
    }
}
</script>

<style lang="scss" scoped>
    .container-banner {
        position: relative;
        height: 470px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        @include breakpoint($max: 1700) {
            background-position: center !important;
        }
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.349);
        }
    }

    h1 {
        position: relative;
        color: white;
        font-weight: 1000;
        font-size: 80px;
        padding-top: 180px;
        margin: 0px 20px;
        font-size: 50px;
    }
    @media (max-width: 800px) {
        h1 {
            font-size: 35px;
        }
    }
</style>
