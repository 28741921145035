<template>
    <div class="video-download">
        <a
            target="_blank"
            :href="getVideo(video)">
            <div
                v-tilt
                class="video">
                <div class="play">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="96"
                        height="96"
                        viewBox="0 0 24 24"
                        fill="white">
                        <path
                            d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3 17v-10l9 5.146-9 4.854z" />
                    </svg>
                </div>

                <img
                    :src="getImage(image)" />
            </div>
        </a>

        <div class="content">
            <div class="title">
                {{ title }}
            </div>

            <div class="download">
                <a
                    href="#"
                    @click.prevent="
                        downloadItem()
                    ">
                    <img src="../../static/icons/download.png" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { getMedia } from '@/utils/common'
import axios from 'axios'
import { strapiBase } from '@/utils/environment'

export default {
    props: {
        title: { type: String, default: '' },
        image: { type: Object },
        video: { type: Object },
        file: { type: Object }
    },
    computed: {

    },
    methods: {
        getImage (media) {
            return getMedia(media)
        },
        getVideo (media) {
            return getMedia(media, 'video')
        },
        async downloadItem () {
            const media = this.file ? this.file : this.image
            const response = await axios.get(media.data.attributes.url, { baseURL: strapiBase, responseType: 'blob' })
            const blob = new Blob([response.data], { type: media.data.attributes.mime })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = media.data.attributes.name
            link.click()
            URL.revokeObjectURL(link.href)
        }
    }
}
</script>

<style lang="scss" scoped>
.video-download {
    margin: 20px;
    @include breakpoint($max: mobile) {
        width: 100%;
        margin: 0px;
        margin-bottom: 20px;
    }
    @include flex(column, normal, stretch);
    .video {
        position: relative;
        .play {
            z-index: 5;
            position: absolute;
            top : 50%;
            left : 50%;
            transform: translate(-50%, -50%);

        }
        width: 360px;
        @include breakpoint($max: mobile) {
            width: 100%;
        }
        img {
            width: 100%;
            border-radius: 10px;
            opacity: 0.8;
        }

    }
    .content {
        width: 100%;
        margin-top: 20px;
        @include flex(row, center, space-between);
        flex-wrap: nowrap;

        .title {
            font-weight: bolder;
            font-size: 20px;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 300px;
        }

        .download {
            width: 34px;
            img {
                width: 100%;
            }
        }
    }
}

</style>
